import React from 'react'

import tw from 'twin.macro'

function FullPageTitle({ tagName, color, children }) {
  const Heading = ['', ' '].includes(tagName) ? 'h1' : tagName

  return (
    <div
      css={tw`text-2xl xs:text-2xl sm:text-4xl lg:text-5xl font-bold !leading-[1.1]`}
      className={`${color}`}
    >
      <Heading>{children}</Heading>
    </div>
  )
}

export default FullPageTitle
