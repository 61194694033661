import React from 'react'

import tw, { styled, theme } from 'twin.macro'

const AnimatedText = ({ children }) => {
  return <Div>{children}</Div>
}

const Div = styled.div`
  &,
  & * {
    animation: flow 10s ease-in-out infinite;
    background: linear-gradient(
      122.26deg,
      ${theme`colors.secondary`},
      ${theme`colors.secondary`},
      #ffffff,
      ${theme`colors.secondary`},
      #ffffff
    );
    background-size: 200%;
    background-clip: text;
    --webkit-text-fill-color: transparent;

    ${tw`font-sans text-transparent`}
  }
`

export default AnimatedText
