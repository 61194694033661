import React from 'react'

import tw, { styled, theme } from 'twin.macro'

import AnimatedMockup from '@components/AnimatedMockup'

interface FullPageSectionProps {
  title: React.ReactNode
  description?: React.ReactNode
  image?: any
  isTitleCentered?: boolean
  isSingleCol?: boolean
  goToLink?: string | React.ReactNode
  children?: React.ReactNode
  isDecoupled?: boolean
  aboutus?: boolean
}

const FullPageSection: React.FC<FullPageSectionProps> = ({
  title,
  description,
  image,
  children,
  isTitleCentered,
  isSingleCol,
  goToLink,
  isDecoupled = false,
  aboutus = false
}) => {
  if (aboutus) {
    image = (
      <>
        <AnimatedMockup />
      </>
    )
  }

  return (
    <FPSection tw="container" isDecoupled={isDecoupled}>
      {!isTitleCentered && (
        <div tw="w-full flex flex-col h-full">
          <div className="row" tw="pt-16 justify-between">
            <div className="texts">
              <>{title}</>
              <div className="descriptionContainer">{description}</div>
            </div>

            <div className="image">
              <div tw="relative w-full">{image}</div>
            </div>
          </div>
          <div className="servicesContainer">{children}</div>
        </div>
      )}
      {isTitleCentered && (
        <div
          className="row"
          tw="h-full flex-col pt-24 sm:pt-0 sm:justify-center relative"
        >
          <div className={`${isDecoupled ? '' : 'titleCentered'}`}>
            <div tw="sm:text-center">{title}</div>
            <div tw="w-full">{children}</div>
          </div>
          {goToLink && (
            <div className="servicesContainer" tw="absolute bottom-0">
              {goToLink}
            </div>
          )}
        </div>
      )}
    </FPSection>
  )
}

interface FPSectionProps {
  isDecoupled?: boolean
}

const FPSection = styled.div<FPSectionProps>`
  ${tw`mx-auto px-6 pt-8 relative h-full`}
  ${tw`sm:pt-32`}
  ${tw`md:pt-0`}
  ${tw`lg:px-8`}


  .row {
    ${tw`flex flex-wrap flex-grow`}
  }

  .texts {
    ${tw`mb-4 mt-8 flex flex-col pr-4`}
    ${tw`md:mt-0 md:w-[45%] md:justify-center`}
  }

  .descriptionContainer {
    ${tw`pt-4 font-medium text-xs leading-relaxed`}
    ${tw`xs:text-sm sm:text-base`}
    ${tw`md:pl-6 md:pt-4 md:text-lg`}
  }

  .image {
    ${tw`w-10/12 xs:w-full px-4 flex justify-center items-center`}
    ${tw`sm:w-3/4 sm:mx-auto md:mx-0`}
    ${tw`md:w-1/2 md:items-center md:justify-end`}
    height: 35%;

    @media (min-width: ${theme`screens.sm`}) {
      height: 65%;
    }

    @media (min-width: ${theme`screens.md`}) {
      height: 100%;
    }
  }

  .servicesContainer {
    ${tw`flex flex-col px-4 mb-6 w-full`}
    ${tw`lg:px-8`}
  }

  .titleCentered {
    ${tw`w-full mb-4 flex flex-col sm:pr-4`}
    ${props => !props.isDecoupled && tw`-mt-12`}
  }

  .texts,
  .titleCentered {
    ${tw`xs:mb-0 xs:pr-4`}
    ${tw`sm:pr-0`}
  }
`

export default FullPageSection
