import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import tw from 'twin.macro'

import WoocommerceIcon from '@svg/woocommerce.svg'
import DecoIcon from '@svg/deco.svg'

import FullPageTitle from '@components/shared/fullpage/FullPageTitle'
import FullPageSecondTitle from '@components/shared/fullpage/FullPageSecondTitle'
import FullPageSection from '@components/shared/fullpage/FullPageSection'
import FullpageLinkSlider from '@components/shared/fullpage/FullPageLinkSlider'

function Ecommerce({
  color,
  titleColor,
  textColor,
  data,
  isDecoupled = false
}) {
  return (
    <div className={`section ${color}`}>
      <FullPageSection
        title={
          <FullPageTitle tagName={data.tag} color={titleColor}>
            <div dangerouslySetInnerHTML={{ __html: data.title }}></div>
          </FullPageTitle>
        }
        description={<p className={textColor}>{data.text}</p>}
        image={
          <GatsbyImage
            image={data.image.localFile.childImageSharp.gatsbyImageData}
            alt={data.image.altText}
            style={{ marginLeft: 'auto' }}
          />
        }
      >
        <FullPageSecondTitle
          tagName="div"
          titleColor={titleColor}
          textColor={textColor}
        >
          NOSSOS SERVIÇOS
        </FullPageSecondTitle>
        <FullpageLinkSlider titleColor={titleColor}>
          <Link to="/woocommerce" tw="flex items-center">
            <WoocommerceIcon className={`fill-current ${textColor}`} />
            <p css={tw`ml-2`}>E-commerce Woocommerce</p>
          </Link>
          <Link to="/deco" className="flex">
            <DecoIcon tw="w-[24px]" className={`fill-current ${textColor}`} />
            <p tw="ml-2">Deco Headless Commerce</p>
          </Link>
        </FullpageLinkSlider>
      </FullPageSection>
    </div>
  )
}

export default Ecommerce
