import React, { useEffect } from 'react'
import { Link } from 'gatsby'

import tw, { styled, theme } from 'twin.macro'

import ContactForm from '@components/ContactForm'
import FullPageTitle from '@components/shared/fullpage/FullPageTitle'
import FullPageSection from '@components/shared/fullpage/FullPageSection'

import Facebook from '@svg/facebook.svg'
import Instagram from '@svg/instagram.svg'
import Linkedin from '@svg/linkedin.svg'
import Whatsapp from '@svg/whatsapp.svg'
import DecoPartner from '@svg/deco-partner.svg'

import Map from '@components/shared/Map'

function Contact({
  color,
  titleColor,
  textColor,
  isDecoupled = false,
  data = null,
  singleColumn = false,
  hideFooter = false
}) {
  return (
    <ContactWrapper className={`section ${color}`} isDecoupled={isDecoupled}>
      <FullPageSection
        isTitleCentered
        isDecoupled={isDecoupled}
        title={
          <FullPageTitle
            tagName={isDecoupled ? 'div' : 'h2'}
            color={titleColor}
          >
            <div
              tw="lg:inline"
              css={[
                !isDecoupled && tw`hidden`,
                singleColumn && tw`!hidden lg:!hidden`
              ]}
            >
              <div tw="pb-0 2xl:pb-16">Onde estamos</div>
            </div>
            <div css={[isDecoupled ? tw`hidden` : tw`lg:hidden`]}>
              Solicite nosso contato
            </div>
          </FullPageTitle>
        }
      >
        <div tw="flex flex-col h-full">
          <div tw="flex flex-wrap md:justify-between mt-4 sm:mt-8 h-full items-center xl:pb-4 2xl:pb-16 flex-grow">
            <div
              tw="w-full"
              css={[
                singleColumn ? tw`lg:w-full` : tw`lg:w-5/12`,
                !isDecoupled && tw`pr-2`
              ]}
            >
              <p
                className="title"
                css={[
                  isDecoupled && tw`block`,
                  singleColumn && tw`!text-primary`
                ]}
              >
                solicite nosso contato
              </p>
              <ContactForm singleColumn={singleColumn} textColor={textColor} />
            </div>

            <div
              tw="lg:w-5/12 md:px-4 mt-8 md:mt-0"
              css={[
                !isDecoupled && tw`hidden lg:block`,
                singleColumn && tw`hidden`
              ]}
            >
              <div tw="flex flex-wrap text-white text-sm justify-between">
                <address tw="not-italic mb-4 md:mb-0">
                  Av. das Américas, 4200 - Barra da Tijuca <br />
                  Bl 1 SL 305 - Rio de Janeiro - RJ
                </address>
                <div tw="md:text-right">
                  <p>
                    Telefone{' '}
                    <a tw="font-bold" href="tel:+552134003141" target="_blank">
                      (21) 3400-3141
                    </a>
                  </p>
                </div>
              </div>
              <div tw="mt-4">
                <Map />
              </div>
            </div>
          </div>

          <footer
            className="footer"
            css={[
              isDecoupled && tw`mt-24`,
              !isDecoupled && tw`!absolute bottom-0`
            ]}
          >
            <div tw="flex justify-between flex-wrap-reverse items-center">
              {!hideFooter && (
                <div>
                  © 2024 onSERP Marketing - Todos os direitos reservados.{' '}
                  <Link to="/politica-de-privacidade">
                    Política de privacidade
                  </Link>
                </div>
              )}

              <div className="socialLinks">
                <a href="https://facebook.com/onserp" target="_blank">
                  <Facebook tw="fill-current text-secondary" />
                </a>
                <a
                  href="https://instagram.com/onserp.marketing"
                  target="_blank"
                >
                  <Instagram tw="fill-current text-secondary" />
                </a>
                <a
                  href="https://www.linkedin.com/company/onserp/"
                  target="_blank"
                >
                  <Linkedin tw="fill-current text-secondary" />
                </a>
              </div>
            </div>
            {!hideFooter && (
              <div tw="flex justify-end items-center gap-2">
                <p>Parceiros:</p>{' '}
                <span>
                  <a
                    href="https://deco.cx/?utm_source=onserp_marketing"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <DecoPartner tw="w-16 h-16" />
                  </a>
                </span>
                <span>
                  <a
                    href="https://www.semrush.com/agencies/onserp-marketing/"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <img
                      src="https://static.semrush.com/agency-directory/shared/badge.svg"
                      width={60}
                      height={60}
                      alt=""
                    />{' '}
                  </a>
                </span>
              </div>
            )}
          </footer>
        </div>
      </FullPageSection>
    </ContactWrapper>
  )
}

interface ContactWrapperProps {
  isDecoupled?: boolean
}

const ContactWrapper = styled.div<ContactWrapperProps>`
  ${props => props.isDecoupled && tw`py-20`}

  .title {
    ${tw`font-semibold text-white text-sm uppercase mb-6 hidden`}
    letter-spacing: 0.2rem;
    ${tw`sm:text-xl`}
    ${tw`lg:block`}
  }

  .footer {
    ${tw`text-white relative text-xs px-4 pb-2 w-full`}
  }

  .footer::before {
    content: '';
    transform: translateX(-80%);
    ${tw`w-screen h-1 bg-secondary block absolute rounded-full top-0 -mt-4`}
    ${tw`xs:w-screen h-1 xs:bg-secondary xs:block xs:absolute xs:rounded-full xs:top-0 xs:-mt-6`}
    ${tw`sm:text-sm`}

    @media (min-width: ${theme`screens.xs`}) {
      transform: translateX(-75%);
    }
  }

  .socialLinks {
    ${tw`flex mb-2`}
    ${tw`xs:mb-4`}
  }

  .socialLinks a {
    ${tw`inline-block mr-4`}
  }

  .socialLinks svg {
    ${tw`transform scale-75`}
    ${tw`xs:transform scale-100`}
  }
`

export default Contact
