// sections
import Seo from '@components/homeSections/Seo'
import Site from '@components/homeSections/Site'
import Ecommerce from '@components/homeSections/Ecommerce'
import Clients from '@/components/homeSections/Clients'
import AboutUs from '@components/homeSections/AboutUs'
import Blog from '@/components/homeSections/Blog'
import Contact from '@/components/homeSections/Contact'
import SeoAgency from '@/components/homeSections/SeoAgency'

/**
 * List of sections that will be rendered
 */

type Section = {
  name: string
  Section: React.FC<{
    color: any
    titleColor: any
    textColor: any
    data: any
    isDecoupled?: boolean
  }>
  color: string
  titleColor: string
  textColor: string
  slug: string
  data?: any
  viewed?: boolean
}

const sections: Section[] = [
  {
    name: 'Bem-Vindo',
    Section: SeoAgency,
    color: 'bg-primary',
    titleColor: 'text-white',
    textColor: 'text-secondary',
    slug: 'welcome'
  },
  {
    name: 'SEO',
    Section: Seo,
    color: 'bg-secondary',
    titleColor: 'text-white',
    textColor: 'text-primary',
    slug: 'seo'
  },
  {
    name: 'Site',
    Section: Site,
    color: 'bg-white',
    titleColor: 'text-primary',
    textColor: 'text-secondary',
    slug: 'site'
  },
  {
    name: 'E-commerce',
    Section: Ecommerce,
    color: 'bg-ice',
    titleColor: 'text-primary',
    textColor: 'text-secondary',
    slug: 'ecommerce'
  },
  {
    name: 'Clientes',
    Section: Clients,
    color: 'bg-dark',
    titleColor: 'text-white',
    textColor: 'text-white',
    slug: 'clients'
  },
  {
    name: 'Sobre Nós',
    Section: AboutUs,
    color: 'bg-white',
    titleColor: 'text-primary',
    textColor: 'text-secondary',
    slug: 'aboutus'
  },
  /*   {
    name: 'Blog',
    Section: Blog,
    color: 'bg-secondary',
    titleColor: 'text-white',
    textColor: 'text-primary',
    slug: 'blog'
  }, */
  {
    name: 'Contato',
    Section: Contact,
    color: 'bg-primary',
    titleColor: 'text-white',
    textColor: 'text-white',
    slug: 'contact'
  }
]

export default sections
