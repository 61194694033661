import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import tw, { styled, theme } from 'twin.macro'

import FullPageTitle from '@components/shared/fullpage/FullPageTitle'
import FullPageSection from '@components/shared/fullpage/FullPageSection'
import slugify from '@utils/slugify'

import { useMinScreen } from '@utils/hooks'

import QuoteIcon from '@svg/quote.svg'
import Arrow from '@svg/arrow.svg'

import SwiperCore, { Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

SwiperCore.use([Autoplay, Navigation])

interface ClientsProps {
  isDecoupled?: boolean
  color?: string
  titleColor?: string
  textColor?: string
  data?: any
}

const Clients: React.FC<ClientsProps> = ({
  color,
  titleColor,
  textColor,
  isDecoupled,
  data = null
}) => {
  const { depoimentos, clientes } = useStaticQuery(graphql`
    query clientsComponent {
      depoimentos: allWpTestimonial {
        nodes {
          title
          acfDepoimento {
            cargo
          }
          content
        }
      }
      clientes: allWpClient {
        nodes {
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    height: 64
                    placeholder: BLURRED
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const [depoimentoIndex, setDepoimentoIndex] = useState<number>(null)

  const { min } = useMinScreen()

  return (
    <ClientsSection className="section" isDecoupled={isDecoupled}>
      <FullPageSection
        isTitleCentered
        isDecoupled={isDecoupled}
        title={
          <FullPageTitle tagName="h2" color={titleColor}>
            O que nossos clientes dizem
          </FullPageTitle>
        }
      >
        <div tw="flex flex-col h-full">
          <div className={`${textColor} ${`swiperContainer`}`}>
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{ delay: 6000 }}
              loop={true}
              onSlideChange={e => setDepoimentoIndex(e.realIndex)}
              grabCursor={true}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
              }}
              breakpoints={{
                991: {
                  slidesPerView: 3,
                  spaceBetween: 30
                }
              }}
            >
              {depoimentos &&
                depoimentos.nodes.map(
                  ({ title, acfDepoimento: { cargo }, content }) => (
                    <SwiperSlide key={slugify(title)}>
                      <div className="depoimento">
                        <QuoteIcon className="quote" />
                        <div
                          dangerouslySetInnerHTML={{ __html: content }}
                        ></div>
                        <div className="depoimentoAutor">
                          <p className="font-bold">{title}</p>
                          <p className="font-light">{cargo}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                )}
            </Swiper>

            <div className="swiperNavigation">
              <div className="swiper-button-prev">
                <Arrow tw="text-white fill-current scale-75 rotate-180 transform" />
              </div>
              <div tw="font-sans2 leading-none tracking-widest">
                <span tw="text-xl">{depoimentoIndex + 1}</span>
                <span tw="text-sm">/6</span>
              </div>
              <div className="swiper-button-next">
                <Arrow tw="text-white fill-current scale-75 transform" />
              </div>
            </div>
          </div>
          <div className="logos" tw="container mx-auto">
            {min`lg` ? (
              <div tw="flex justify-between">
                {clientes &&
                  clientes.nodes.map(({ featuredImage }, index) => (
                    <div key={index}>
                      <div className="logo">
                        <GatsbyImage
                          image={
                            featuredImage.node.localFile.childImageSharp
                              .gatsbyImageData
                          }
                          alt={featuredImage.node.altText}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <Swiper
                slidesPerView={2.5}
                loop={true}
                grabCursor={true}
                autoplay={{
                  delay: 4000
                }}
                breakpoints={{
                  600: {
                    slidesPerView: 4
                  }
                }}
              >
                {clientes &&
                  clientes.nodes.map(({ featuredImage }, index) => (
                    <SwiperSlide key={index}>
                      <div className="logo">
                        <GatsbyImage
                          image={
                            featuredImage.node.localFile.childImageSharp
                              .gatsbyImageData
                          }
                          alt={featuredImage.node.altText}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            )}
          </div>
        </div>
      </FullPageSection>
    </ClientsSection>
  )
}

interface ClientsSectionProps {
  isDecoupled?: boolean
}

const ClientsSection = styled.div<ClientsSectionProps>`
  background: #111;
  background: radial-gradient(
    at bottom right,
    rgba(50, 50, 50, 1) 0%,
    rgba(0, 0, 0, 1) 80%
  );

  ${props => props.isDecoupled && tw`py-20`}

  .swiperContainer {
    ${tw`flex flex-wrap flex-grow w-full max-w-full`}
    ${tw`lg:px-16 lg:mt-10`}
  }

  .swiperNavigation {
    ${tw`flex my-4`}
    ${tw`lg:hidden`}
  }

  .swiperNavigation > div {
    ${tw`mr-3`}
  }

  .depoimento {
    ${tw`text-left text-base bg-transparent pt-12 px-2`}
    ${tw`sm:pt-16 sm:px-4`}
    ${tw`lg:text-center`}
    backface-visibility: hidden;
  }

  .depoimentoAutor {
    ${tw`mt-4`}
  }

  .quote {
    ${tw`absolute fill-current text-white`}
    transform: translate3D(-20%, -60%, 0) scale(0.75);
    opacity: 0.12;

    @media (min-width: ${theme`screens.md`}) {
      transform: translate3D(-15%, -50%, 0) scale(1);
    }
  }

  .logos {
    ${tw`bottom-0 mb-8 text-white`}

    ${props => props.isDecoupled && tw`pt-12 md:pt-24`}
    ${props => !props.isDecoupled && tw`absolute`}
  }

  .logos::before {
    content: '';
    ${tw`h-1 w-40 rounded-full block m-auto mb-8`}
    ${tw`md:w-64 md:mb-10`}
    background: rgba(255, 255, 255, 0.3);
  }

  .logo {
    ${tw`flex justify-center items-center h-16`}
    ${tw`md:h-20`}
  }

  .logo img {
    ${tw`object-contain object-center`}
  }
`

export default Clients
