import React from 'react'
import 'lazysizes'

function Map() {
  return (
    <iframe
      title="Mapa com endereço"
      className="lazyload"
      data-src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14702.144701820083!2d-43.1967135!3d-22.8935863!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfbadf598b9761f1a!2sonSERP%20Marketing%20-%20Ag%C3%AAncia%20de%20SEO%20e%20desenvolvimento%20de%20site%20e%20E-commerce!5e0!3m2!1spt-BR!2sbr!4v1607527291720!5m2!1spt-BR!2sbr"
      width="100%"
      height="250"
      style={{ border: 0 }}
      frameBorder="0"
      allowFullScreen
      aria-hidden="false"
      tabIndex={0}
    ></iframe>
  )
}

export default Map
