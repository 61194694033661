import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import tw from 'twin.macro'

// icons
import SeoIcon from '@svg/seo.svg'
import CartIcon from '@svg/cart.svg'
import AuditIcon from '@svg/auditoria.svg'
import GrowthIcon from '@svg/growth.svg'

import FullPageTitle from '@components/shared/fullpage/FullPageTitle'
import FullPageSecondTitle from '@components/shared/fullpage/FullPageSecondTitle'
import FullPageSection from '@components/shared/fullpage/FullPageSection'
import FullpageLinkSlider from '@components/shared/fullpage/FullPageLinkSlider'

function Seo({ color, titleColor, textColor, data, isDecoupled = false }) {
  return (
    <div className={`section ${color}`}>
      <FullPageSection
        title={
          <FullPageTitle tagName={data.tag} color={titleColor}>
            <div dangerouslySetInnerHTML={{ __html: data.title }}></div>
          </FullPageTitle>
        }
        description={<p className={textColor}>{data.text}</p>}
        image={
          <GatsbyImage
            image={data.image.localFile.childImageSharp.gatsbyImageData}
            alt={data.image.altText}
            style={{ marginLeft: 'auto' }}
          />
        }
      >
        <FullPageSecondTitle
          tagName="div"
          titleColor={titleColor}
          textColor={textColor}
        >
          NOSSOS SERVIÇOS
        </FullPageSecondTitle>
        <FullpageLinkSlider titleColor={titleColor}>
          {/* <Link to="/seo-360" className="flex">
            <SeoIcon className={`fill-current ${textColor}`} />
            <p css={tw`ml-2`}>SEO 360°</p>
          </Link>
          <Link to="/seo-para-e-commerce" className="flex">
            <CartIcon className={`fill-current ${textColor}`} />
            <p tw="ml-2">SEO para e-commerce</p>
          </Link>
          <Link to="/auditoria-de-seo" className="flex">
            <AuditIcon className={`fill-current ${textColor}`} />
            <p tw="ml-2">Auditoria de SEO</p>
          </Link> */}
          <Link to="/agencia-seo-360" className="flex">
            <SeoIcon className={`fill-current ${textColor}`} />
            <p css={tw`ml-2`}>SEO 360°</p>
          </Link>
          <Link to="/seo-para-e-commerce" className="flex">
            <CartIcon className={`fill-current ${textColor}`} />
            <p tw="ml-2">SEO para e-commerce</p>
          </Link>
          <div className="flex">
            <GrowthIcon tw="w-[24px]" className={`fill-current ${textColor}`} />
            <p tw="ml-2">Organic Growth</p>
          </div>
          <div className="flex">
            <AuditIcon className={`fill-current ${textColor}`} />
            <p tw="ml-2">Auditoria de SEO</p>
          </div>
        </FullpageLinkSlider>
      </FullPageSection>
    </div>
  )
}

export default Seo
