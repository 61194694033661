import '@styles/styles.scss'
import 'typeface-raleway'

import React from 'react'
import { MinScreenProvider } from '@context/minScreen'
import { FullPageProvider } from '@context/fullPageContext'
import { theme } from 'twin.macro'

import Cookie from '@components/Cookie'

import { GlobalStyles } from 'twin.macro'

const Wrapper = ({ element }) => {
  return (
    <>
      <FullPageProvider>
        <MinScreenProvider screens={theme`screens`}>
          <GlobalStyles />
          {element}
          <Cookie />
        </MinScreenProvider>
      </FullPageProvider>
    </>
  )
}

export const wrapRootElement = ({ element }) => {
  return <Wrapper element={element}></Wrapper>
}
