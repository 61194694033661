import React, { useRef } from 'react'

import tw from 'twin.macro'
import { motion, useInView } from 'framer-motion'

function FullpageLinkSlider({ titleColor, children }) {
  const childElements = React.Children.toArray(children)

  const variants = {
    container: {
      hidden: { opacity: 0 },
      show: {
        opacity: 1,
        transition: {
          staggerChildren: 0.35,
          delayChildren: 0.3
        }
      }
    },
    children: {
      hidden: { opacity: 0, x: 300 },
      show: {
        opacity: 1,
        x: 0
      }
    }
  }

  const element = useRef(null)
  const isInView = useInView(element, { once: true })

  return (
    <div
      className="normal-scroll"
      css={tw`pl-6 relative w-full h-12 overflow-x-auto overflow-y-hidden lg:pl-20`}
    >
      <motion.div
        tw="flex pt-4 absolute"
        ref={element}
        variants={variants.container}
        initial="hidden"
        animate={isInView ? 'show' : 'hidden'}
      >
        {children &&
          childElements &&
          childElements.map((child, index) => (
            <motion.div
              variants={variants.children}
              key={index}
              className={`${titleColor}`}
              css={tw`inline-block text-sm mr-10 whitespace-nowrap leading-6 md:text-lg`}
            >
              {child}
            </motion.div>
          ))}
      </motion.div>
    </div>
  )
}

export default FullpageLinkSlider
