import tw, { styled } from 'twin.macro'
import { useState, useEffect } from 'react'

const Cookie = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (
      'acceptedCookies' in localStorage &&
      localStorage.acceptedCookies === 'true'
    ) {
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }, [])

  const handleClose = e => {
    e.preventDefault()
    setIsVisible(false)
    localStorage.setItem('acceptedCookies', 'true')
  }

  return (
    <CookieContainer isVisible={isVisible}>
      <p tw="text-gray-900">
        Ao navegar em nosso site você concorda com a nossa política de
        privacidade.
      </p>
      <div>
        <CookieButton href="#" onClick={e => handleClose(e)}>
          OK
        </CookieButton>
        <CookieButton href="/politica-de-privacidade">
          Política de privacidade
        </CookieButton>
      </div>
    </CookieContainer>
  )
}

export default Cookie

const CookieButton = styled.a`
  ${tw`py-1 px-3 sm:py-2 sm:px-6 inline-flex mr-2 bg-secondary text-white rounded-lg`}
`
interface CookieContainerProps {
  isVisible: boolean
}

const CookieContainer = styled.div<CookieContainerProps>`
  ${tw`w-80 lg:w-96 fixed bottom-4 lg:bottom-8 right-4 lg:right-8 px-4 py-6 bg-white border border-gray-100 shadow-xl rounded-xl`}
  ${props => (props.isVisible ? tw`block` : tw`hidden`)}
  z-index: 999;

  p {
    ${tw`text-sm mb-8`}
  }
`
