import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import tw from 'twin.macro'

import WordpressIcon from '@svg/wordpress.svg'
import JamstackIcon from '@svg/jamstack.svg'

import FullPageTitle from '@components/shared/fullpage/FullPageTitle'
import FullPageSecondTitle from '@components/shared/fullpage/FullPageSecondTitle'
import FullPageSection from '@components/shared/fullpage/FullPageSection'
import FullpageLinkSlider from '@components/shared/fullpage/FullPageLinkSlider'

function Site({ color, titleColor, textColor, data, isDecoupled = false }) {
  return (
    <div className={`section ${color}`}>
      <FullPageSection
        title={
          <FullPageTitle tagName={data.tag} color={titleColor}>
            <div dangerouslySetInnerHTML={{ __html: data.title }}></div>
          </FullPageTitle>
        }
        description={<p className={textColor}>{data.text}</p>}
        image={
          <GatsbyImage
            image={data.image.localFile.childImageSharp.gatsbyImageData}
            alt={data.image.altText}
            style={{ marginLeft: 'auto' }}
          />
        }
      >
        <FullPageSecondTitle
          tagName="div"
          titleColor={titleColor}
          textColor={textColor}
        >
          NOSSOS SERVIÇOS
        </FullPageSecondTitle>
        <FullpageLinkSlider titleColor={titleColor}>
          {/* <Link to="/sites-em-wordpress" tw="flex">
            <WordpressIcon className={`fill-current ${textColor}`} />
            <p tw="ml-2">Sites em Wordpress</p>
          </Link>
          <Link to="/sites-jamstack" tw="flex items-center">
            <JamstackIcon tw="w-8" className={`${textColor}`} />
            <p css={tw`ml-2`}>Sites JAMStack</p>
          </Link> */}
          <div tw="flex">
            <WordpressIcon className={`fill-current ${textColor}`} />
            <p tw="ml-2">Sites em Wordpress</p>
          </div>
          <div tw="flex items-center">
            <JamstackIcon tw="w-8" className={`${textColor}`} />
            <p css={tw`ml-2`}>Sites JAMStack</p>
          </div>
        </FullpageLinkSlider>
      </FullPageSection>
    </div>
  )
}

export default Site
