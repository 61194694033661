import React, { useRef, useEffect } from 'react'

import tw from 'twin.macro'
import { StaticImage } from 'gatsby-plugin-image'

import Lottie from 'lottie-react'
import chartAnimation from '@lottie/chart.json'

import { animate, motion, useInView } from 'framer-motion'

import Arrow from '@svg/arrow-up.svg'

const AnimatedMockup = () => {
  const elementRef = useRef(null)

  const lottieRef = useRef<any>()
  const isInView = useInView(elementRef, { once: false })

  useEffect(() => {
    lottieRef.current.stop()
  }, [lottieRef])

  useEffect(() => {
    lottieRef.current.setSpeed(2)

    if (isInView) {
      lottieRef.current.goToAndPlay(0, true)
    } else {
      lottieRef.current.goToAndStop(0, true)
    }
  }, [isInView])

  return (
    <div css={tw`relative`} ref={elementRef}>
      <div tw="text-2xl sm:text-5xl md:text-4xl lg:text-6xl xl:text-7xl text-secondary font-bold absolute top-4 opacity-80 flex">
        <motion.div
          variants={{
            start: {
              y: 30
            },
            end: {
              y: 0
            }
          }}
          transition={{ duration: 2 }}
          initial="start"
          animate={isInView ? 'end' : 'start'}
        >
          <Arrow tw="text-secondary w-8 sm:w-12 md:w-10 lg:w-14 xl:w-20" />
        </motion.div>
        <Counter from={0} to={180} duration={2}></Counter>
      </div>

      <motion.div
        variants={{
          start: {
            y: 100
          },
          end: {
            y: 0
          }
        }}
        transition={{ duration: 2 }}
        initial="start"
        animate={isInView ? 'end' : 'start'}
        tw="relative z-10"
      >
        <StaticImage src="../assets/images/mockup.png" alt=""></StaticImage>
      </motion.div>
      <div tw="max-w-[9rem] sm:max-w-[12rem] md:max-w-[11rem] lg:max-w-[14rem] xl:max-w-[20rem] w-full absolute -bottom-8 right-0 z-20">
        <Lottie
          animationData={chartAnimation}
          lottieRef={lottieRef}
          loop={false}
          tw="opacity-70"
        />
      </div>
    </div>
  )
}

function Counter({ from, to, duration }) {
  const nodeRef = useRef<HTMLParagraphElement>(null)
  const isInView = useInView(nodeRef, { once: false })

  useEffect(() => {
    const node = nodeRef.current

    const controls = animate(from, to, {
      duration: duration,
      onUpdate(value) {
        node.textContent = value.toFixed() + '%'
      }
    })

    return () => controls.stop()
  }, [from, to, duration, isInView])

  return <p ref={nodeRef} />
}

export default AnimatedMockup
