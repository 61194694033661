import { useState, createContext } from 'react'
import sections from '@data/home'

interface IFPContext {
  fpData: typeof sections
  setFpData: (data: typeof sections) => void
}

const FullPageContext = createContext<IFPContext>(null)

const FullPageProvider = ({ children }) => {
  const [fpData, setFpData] = useState(sections)

  return (
    <FullPageContext.Provider value={{ fpData, setFpData }}>
      {children}
    </FullPageContext.Provider>
  )
}

export { FullPageContext, FullPageProvider }
