import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import FullPageTitle from '@components/shared/fullpage/FullPageTitle'
import FullPageSecondTitle from '@components/shared/fullpage/FullPageSecondTitle'
import FullPageSection from '@components/shared/fullpage/FullPageSection'
import { Link } from 'gatsby'

function AboutUs({ color, titleColor, textColor, data, isDecoupled = false }) {
  return (
    <div className={`section ${color}`}>
      <FullPageSection
        aboutus
        title={
          <FullPageTitle tagName={data.tag} color={titleColor}>
            <div dangerouslySetInnerHTML={{ __html: data.title }}></div>
          </FullPageTitle>
        }
        description={<p className={textColor}>{data.text}</p>}
        image={
          <GatsbyImage
            image={data.image.localFile.childImageSharp.gatsbyImageData}
            alt={data.image.altText}
            style={{ marginLeft: 'auto' }}
          />
        }
      >
        <FullPageSecondTitle
          tagName="div"
          titleColor={titleColor}
          textColor={textColor}
        >
          {/*  <Link className="flex" to="/sobre-nos">
            <div>conheça mais sobre nós</div>
            <svg
              className="inline-block xs:ml-4"
              width="28"
              height="24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.06 21.255h0l-.008.01a1.2 1.2 0 001.797 1.584l10-10h0l.008-.01.083-.094.008-.009.007-.01.064-.092.006-.009.005-.01.053-.097.005-.01.004-.011.044-.111.005-.011.003-.012.03-.111.002-.012.002-.012.017-.125.002-.01v-.01l.003-.076v-.016l-.004-.09v-.007l-.001-.008-.007-.058-.002-.01-.001-.008-.025-.117-.003-.012-.003-.01-.036-.106-.003-.011-.005-.011-.054-.113-.006-.011-.006-.011-.072-.112h0l-.008-.01a1.202 1.202 0 00-.115-.134l-10-10h0l-.01-.009-.094-.083h0l-.01-.008a1.2 1.2 0 00-1.583.1h0l-.009.009-.083.094h0l-.008.01a1.2 1.2 0 00.1 1.584l7.951 7.951H2h0-.012l-.116.007h0l-.012.001A1.2 1.2 0 002 13.2h21.103l-7.952 7.951h0l-.008.01-.083.094z"
                className={`transition-colors duration-300 ease-in fill-current stroke-current ${titleColor}`}
                strokeWidth=".4"
              />
            </svg>
          </Link> */}
        </FullPageSecondTitle>
      </FullPageSection>
    </div>
  )
}

export default AboutUs
