import { useContext } from 'react'
import { ScreensContext, defaultValue } from '@context/minScreen'

const useMinScreen = () => {
  const context = useContext(ScreensContext)
  if (context === defaultValue)
    throw new Error('useMinScreen must be used within a MinScreenProvider')

  return { min: size => context[size] }
}

export default useMinScreen
