import React, { useRef } from 'react'

import tw from 'twin.macro'
import { motion, useInView } from 'framer-motion'

function FullPageTitle({ tagName, titleColor, textColor, children }) {
  const Heading = tagName

  const element = useRef(null)
  const isInView = useInView(element, { once: true })

  const variants = {
    line: {
      hidden: { opacity: 0, x: -200 },
      show: {
        opacity: 1,
        x: 0,
        transition: {
          delay: 0.3
        }
      }
    },
    title: {
      hidden: { opacity: 0, x: 100 },
      show: {
        opacity: 1,
        x: 0,
        transition: {
          delay: 0.3
        }
      }
    }
  }

  return (
    <div className="relative" ref={element}>
      <motion.div
        variants={variants.line}
        initial="hidden"
        animate={isInView ? 'show' : 'hidden'}
      >
        <div
          className={`${textColor}`}
          css={tw`bg-current absolute mt-2 h-1 left-0 w-screen rounded-full transform translate-x-[-96%] xs:-translate-x-full lg:translate-x-[-96%] xl:translate-x-[-97%]`}
        ></div>
      </motion.div>
      <div
        className={`${titleColor}`}
        css={tw`font-semibold text-sm uppercase pl-6 sm:text-base lg:pl-20`}
        style={{
          letterSpacing: '0.2em'
        }}
      >
        <motion.div
          variants={variants.title}
          initial="hidden"
          animate={isInView ? 'show' : 'hidden'}
        >
          <Heading>{children}</Heading>
        </motion.div>
      </div>
    </div>
  )
}

export default FullPageTitle
