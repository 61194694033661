import tw from 'twin.macro'
import { navigate } from 'gatsby'
import React, { useState, useEffect, createRef } from 'react'

import Arrow from '@svg/arrow.svg'

import Recaptcha from 'react-google-recaptcha'

function ContactForm({ singleColumn, textColor }) {
  const recaptchaRef = createRef()

  const RECAPTCHA_KEY = '6LcVpMQpAAAAAJJDbXHbf3Pxa8-VP9AyM-cTBmxD'

  const handleSubmit = async e => {
    e.preventDefault()
    const token = await recaptchaRef.current.executeAsync()
    const form = e.target

    // send FormData
    const formData = new FormData(form)
    const state = Object.fromEntries(formData)

    if (state['g-recaptcha-response'] === '') {
      state['g-recaptcha-response'] = token
    }

    const encode = p =>
      Object.entries(p)
        .map(kv => kv.map(encodeURIComponent).join('='))
        .join('&')

    fetch('/sucesso', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state
      })
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  const [buttonDisabled, setButtonDisabled] = useState(true)

  useEffect(() => {
    const formControl = document.querySelectorAll('.form-control')

    formControl.forEach(element => {
      const input = element.querySelector('.input') as HTMLInputElement

      if (!input) return

      if (input.tagName === 'SELECT') {
        // activate select on element click
        element.addEventListener('click', () => {
          input.focus()
        })
      }

      input.addEventListener('focus', () => element.classList.add('active'))
      input.addEventListener('blur', () => {
        if (input?.value.length === 0) {
          element.classList.remove('active')
        }
      })
    })
  }, [])

  return (
    <form
      tw="flex flex-col"
      className={singleColumn ? 'alternative' : ''}
      name="contact-form"
      action="/sucesso"
      method="POST"
      data-netlify="true"
      onSubmit={handleSubmit}
      data-netlify-honeypot="sou-um-robo"
      data-netlify-recaptcha="true"
    >
      <noscript>Este formulário precisa de JavaScript para funcionar.</noscript>
      <input
        type="hidden"
        className="hidden"
        name="form-name"
        value="contact-form"
      />
      <div className="hidden">
        <label htmlFor="current-page">Página de envio do formulário</label>
        <input
          type="text"
          id="current-page"
          name="current-page"
          value={typeof window !== 'undefined' ? window.location.href : ''}
          readOnly
        />
      </div>
      <div className="flex justify-between gap-1 lg:gap-3">
        <p className="form-control w-full">
          <label htmlFor="contact-name">Nome</label>
          <input
            type="text"
            name="contact-name"
            id="contact-name"
            className="input"
            required
          />
        </p>
        <p className="sou-um-robo">
          <label htmlFor="sou-um-robo">Sou um robô</label>
          <input type="text" name="sou-um-robo" id="sou-um-robo" />
        </p>
        <p className="form-control w-full">
          <label htmlFor="contact-email">E-mail</label>
          <input
            type="email"
            name="contact-email"
            id="contact-email"
            className="input"
            required
          />
        </p>
      </div>
      <div className="flex justify-between gap-1 lg:gap-3">
        <p className="form-control w-full">
          <label htmlFor="contact-phone">Telefone</label>
          <input
            type="tel"
            name="contact-phone"
            id="contact-phone"
            className="input"
            required
          />
        </p>
        <p className="form-control w-full">
          <label htmlFor="contact-company">Empresa</label>
          <input
            type="text"
            name="contact-company"
            id="contact-company"
            className="input"
            required
          />
        </p>
      </div>
      <p className="form-control">
        <label htmlFor="contact-site">URL do Site</label>
        <input
          type="text"
          name="contact-site"
          id="contact-site"
          className="input"
          required
        />
      </p>
      <p className="form-control">
        <label htmlFor="contact-service">
          Sobre qual serviço você quer falar?
        </label>
        <select
          name="contact-service"
          id="contact-service"
          className="input"
          required
        >
          <option value=""></option>
          <option value="seo">SEO</option>
          <option value="dev">Desenvolvimento de Site/Ecommerce</option>
        </select>
      </p>
      <p className="form-control">
        <label htmlFor="contact-investment">
          Investimento mensal em marketing
        </label>
        <select
          name="contact-investment"
          id="contact-investment"
          className="input"
          required
        >
          <option value=""></option>
          <option value="até 3 mil">até 3 mil</option>
          <option value="3 a 10 mil">3 a 10 mil</option>
          <option value="10 a 25 mil">10 a 25 mil</option>
          <option value="25 a 50 mil">25 a 50 mil</option>
          <option value="50 a 100 mil">50 a 100 mil</option>
          <option value="acima de 100 mil">acima de 100 mil</option>
        </select>
      </p>
      <p className="form-control">
        <label htmlFor="contact-message">Mensagem</label>
        <textarea
          cols={30}
          rows={5}
          name="contact-message"
          id="contact-message"
          className="input normal-scroll"
          required
        />
      </p>

      <div tw="flex justify-between mt-2 flex-wrap">
        <div tw="mb-2 lg:mb-0">
          <Recaptcha
            ref={recaptchaRef}
            sitekey={RECAPTCHA_KEY}
            size="invisible"
            id="recaptcha-google"
            onChange={() => setButtonDisabled(false)} // disable the disabled button!
          />
          <label tw="flex items-center">
            <input
              className="peer"
              tw="relative appearance-none shrink-0 w-4 h-4 border-2 border-blue-200 rounded-sm bg-white focus:outline-none focus:ring-offset-0 focus:ring-1 focus:ring-blue-100 checked:bg-secondary checked:border-0 disabled:border-slate-400 disabled:bg-slate-400"
              type="checkbox"
              name="contact-newsletter"
              id="contact-newsletter"
              defaultChecked
            />
            <svg
              tw="absolute w-4 h-4 pointer-events-none hidden peer-checked:block stroke-white outline-none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
            <span
              tw="ml-2 text-xs sm:text-sm md:text-base"
              css={[
                textColor === 'text-primary'
                  ? tw`text-primary`
                  : tw`text-slate-300`
              ]}
            >
              Quero receber novidades por e-mail
            </span>
          </label>
        </div>

        <button
          tw="py-1 px-3 sm:py-2 sm:px-6 inline-flex bg-secondary text-white rounded-lg self-end"
          type="submit"
        >
          <div tw="mr-2 uppercase tracking-widest text-sm leading-6">
            Enviar
          </div>
          <Arrow tw="text-white fill-current scale-75 transform" />
        </button>
      </div>
    </form>
  )
}

export default ContactForm
