import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import tw from 'twin.macro'

// icons
import SeoIcon from '@svg/seo.svg'
import DevIcon from '@svg/desenvolvimento.svg'
import Ecommerce from '@svg/cart.svg'

import AnimatedText from '@components/shared/AnimatedText'
import FullPageSecondTitle from '@components/shared/fullpage/FullPageSecondTitle'
import FullPageCustomSection from '@components/shared/fullpage/FullPageCustomSection'
import FullpageLinkSlider from '@components/shared/fullpage/FullPageLinkSlider'

function SeoAgency({
  color,
  titleColor,
  textColor,
  data,
  isDecoupled = false
}) {
  const { tag, title, text } = data

  const Heading = ['', ' '].includes(tag) ? 'h1' : tag

  return (
    <div className={`section ${color}`} tw="h-full">
      <FullPageCustomSection
        title={
          <Heading css={tw`leading-[1.1]`}>
            <AnimatedText>
              <div
                tw="text-5xl lg:text-7xl xl:text-[6rem] 2xl:text-[8rem] font-extrabold text-center"
                dangerouslySetInnerHTML={{ __html: data.title }}
              ></div>
            </AnimatedText>
          </Heading>
        }
        description={
          <p className={textColor} tw="max-w-md mx-auto text-center">
            {data.text}
          </p>
        }
      >
        <FullPageSecondTitle
          tagName="div"
          titleColor={titleColor}
          textColor={textColor}
        >
          O que você precisa?
        </FullPageSecondTitle>
        <FullpageLinkSlider titleColor={titleColor}>
          <a href="#seo" className="flex">
            <SeoIcon className={`fill-current ${textColor}`} />
            <p tw="ml-2">SEO Marketing</p>
          </a>
          <a href="#site" className="flex">
            <DevIcon className={`fill-current ${textColor}`} />
            <p tw="ml-2">Desenvolvimento</p>
          </a>
          <a href="#ecommerce" className="flex">
            <Ecommerce className={`fill-current ${textColor}`} />
            <p tw="ml-2">E-commerce</p>
          </a>
        </FullpageLinkSlider>
      </FullPageCustomSection>
    </div>
  )
}

export default SeoAgency
