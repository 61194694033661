import React from 'react'

import tw, { styled, theme } from 'twin.macro'

interface FullPageCustomSectionProps {
  title: React.ReactNode
  description?: React.ReactNode
  image?: any
  isTitleCentered?: boolean
  isSingleCol?: boolean
  goToLink?: string | React.ReactNode
  children?: React.ReactNode
}

const FullPageCustomSection: React.FC<FullPageCustomSectionProps> = ({
  title,
  description,
  image,
  children,
  isTitleCentered,
  isSingleCol,
  goToLink
}) => {
  return (
    <FPSection tw="container">
      <div tw="w-full flex flex-col h-full">
        <div className="row" tw="pt-16 justify-between">
          <div className="texts">
            <>{title}</>
            <div className="descriptionContainer">{description}</div>
          </div>
        </div>
        <div className="servicesContainer">{children}</div>
      </div>
    </FPSection>
  )
}

const FPSection = styled.div`
  ${tw`mx-auto px-6 pt-16 relative h-full`}
  ${tw`sm:pt-32`}
  ${tw`md:pt-0`}
  ${tw`lg:px-8`}


  .row {
    ${tw`flex flex-wrap justify-center flex-grow`}
  }

  .texts {
    ${tw`mb-4 mt-8 flex flex-col pr-4`}
    ${tw`md:mt-0 md:justify-center`}
  }

  .descriptionContainer {
    ${tw`pt-4 font-medium text-xs leading-relaxed`}
    ${tw`xs:text-base`}
    ${tw`md:pt-4 md:text-lg`}
  }

  .image {
    ${tw`w-10/12 px-4 flex justify-center items-center`}
    ${tw`sm:w-1/2`}
    ${tw`md:w-5/12 md:items-center md:justify-end`}
    height: 35%;

    @media (min-width: ${theme`screens.sm`}) {
      height: 100%;
    }
  }

  .servicesContainer {
    ${tw`flex flex-col px-4 mb-6 w-full`}
    ${tw`lg:px-8`}
  }

  .titleCentered {
    ${tw`w-full mb-4 mt-4 flex flex-col flex-grow pr-4`}
    ${tw`xs:mt-8`}
    ${tw`md:mt-24`}
    ${tw`lg:mt-24`}
  }

  .texts,
  .titleCentered {
    ${tw`xs:mb-0 xs:pr-4`}
    ${tw`sm:pr-0`}
  }
`

export default FullPageCustomSection
